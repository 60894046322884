import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

const SEO = ({ title, description }) => {
  const {
    site: {
      siteMetadata: { title: metaTitle, description: metaDescription },
    },
  } = useStaticQuery(query)

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={`${title} | ${metaTitle}`}
      meta={[{ name: `description`, content: metaDescription }]}
    ></Helmet>
  )
}

export default SEO
